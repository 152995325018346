import { useCallback, forwardRef } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { EndUser } from '../../entities/EndUser';
import { useReadEndUserListQuery } from '../../services/endUserApi';

type EndUserAutocompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<
  AutocompleteProps<EndUser, Multiple, DisableClearable, FreeSolo>,
  'options' | 'renderInput' | 'onInputChange'
> &
  Pick<TextFieldProps, 'label' | 'error' | 'helperText' | 'required'>;

function EndUserAutocomplete<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(props: EndUserAutocompleteProps<Multiple, DisableClearable, FreeSolo>, ref: React.Ref<unknown>): JSX.Element {
  const { label, error, value, helperText, required, ...autocompleteProps } = props;
  const readEndUserList = useReadEndUserListQuery();

  const getOptionLabel = useCallback(
    (option: EndUser | string) => {
      if (typeof option === 'string') {
        return option;
      }
      if (autocompleteProps.getOptionLabel) {
        return autocompleteProps.getOptionLabel(option);
      }
      return option.name;
    },
    [autocompleteProps],
  );

  return (
    <Autocomplete
      autoComplete
      value={value}
      options={readEndUserList.data ?? []}
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputRef={ref}
          error={readEndUserList.isError || error}
          helperText={
            (readEndUserList.error && 'message' in readEndUserList.error && readEndUserList.error.message) ||
            helperText ||
            undefined
          }
          required={required}
        />
      )}
      loading={readEndUserList.isLoading}
      clearOnEscape
      {...autocompleteProps}
    />
  );
}

export default forwardRef(EndUserAutocomplete);
