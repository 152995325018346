import { DataGrid, DataGridProps } from '@top-solution/microtecnica-mui';
import { GridLogicOperator } from '@mui/x-data-grid-premium';
import { useReadEndUserListQuery } from '../../services/endUserApi';
import { useEndUserListGridColumns } from './useEndUserListGridColumns';

type EndUserListGridProps = Omit<
  DataGridProps,
  | 'rows'
  | 'columns'
  | 'loading'
  | 'error'
  | 'pagination'
  | 'paginationMode'
  | 'rowCount'
  | 'page'
  | 'onPageChange'
  | 'pageSize'
  | 'onPageSizeChange'
  | 'sortingMode'
  | 'sortModel'
  | 'onSortModelChange'
  | 'filterMode'
  | 'onFilterModelChange'
  | 'filterModel'
> & {
  columns?: DataGridProps['columns'];
  pmFilter?: string;
};

export function EndUserListGrid(props: EndUserListGridProps): JSX.Element {
  const { columns, pmFilter, ...gridProps } = props;
  const defaultColumns = useEndUserListGridColumns();

  const endUserList = useReadEndUserListQuery();

  return (
    <DataGrid
      rows={endUserList.data ?? []}
      columns={columns ?? defaultColumns}
      sessionStorageId="EndUserListGridDataGrid"
      loading={endUserList.isFetching}
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        },
        filter: pmFilter
          ? {
              filterModel: {
                items: [
                  { field: 'pmOeList', operator: 'equals', value: pmFilter, id: 'pmOeList' + pmFilter },
                  { field: 'pmMroList', operator: 'equals', value: pmFilter, id: 'pmMroList' + pmFilter },
                ],
                logicOperator: GridLogicOperator.Or,
              },
            }
          : undefined,
      }}
      {...gridProps}
      sx={{
        '[data-field="pmOeList"],[data-field="pmMroList"]': {
          gap: 0.25,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }}
    />
  );
}
