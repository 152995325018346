import { useMemo } from 'react';
import { useReadEndUserListQuery } from '../../services/endUserApi';

type UseReadProgramPmUsernameList = ReturnType<typeof useReadEndUserListQuery> & {
  mro: string[];
  oe: string[];
};

export function useReadProgramPmUsernameList(programId: string): UseReadProgramPmUsernameList {
  const readEndUserList = useReadEndUserListQuery();

  const { mro, oe } = useMemo<{ mro: string[]; oe: string[] }>(() => {
    const mroSet = new Set<string>();
    const oeSet = new Set<string>();
    readEndUserList.data?.forEach((endUser) => {
      if (endUser.programId === programId) {
        endUser.pmMroList.forEach(({ username }) => mroSet.add(username));
        endUser.pmOeList.forEach(({ username }) => mroSet.add(username));
      }
    });

    return { mro: [...mroSet], oe: [...oeSet] };
  }, [readEndUserList.data, programId]);
  return {
    ...readEndUserList,
    mro,
    oe,
  };
}
