import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AuthGuard, useAuth } from '@top-solution/microtecnica-utils';
import { GridActionsCellItem, GridActionsColDef, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-premium';
import { UnauthorizedPage } from '../..';
import {
  EndUserDeleteConfirm,
  EndUserEditDialog,
  EndUserListGrid,
  useEndUserListGridColumns,
} from '../../../components/EndUser';
import { EndUserAddButton } from '../../../components/EndUser/EndUserAddButton';
import { EditIcon, DeleteIcon } from '../../../components/Icons';
import { Layout } from '../../../components/Layout';
import { EndUser } from '../../../entities/EndUser';
import { UserRole } from '../../../entities/User';
import { adminSection, endUserSection } from '../../sections';

const breadcrumbs = [{ title: adminSection.title, url: adminSection.url }, { title: endUserSection.title }];
const pinnedColumns = { right: ['actions'] };

export function EndUserListPage(): JSX.Element {
  const { isAdmin, hasRole } = useAuth();
  const [endUserToRemove, setEndUserToRemove] = useState<EndUser | null>(null);
  const [endUserToUpdate, setEndEndUserToUpdate] = useState<EndUser | null>(null);

  const [searchParams] = useSearchParams();

  const handleEditClick = useCallback(
    ({ row }: GridRowParams<EndUser>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setEndEndUserToUpdate(row);
      },
    [],
  );

  const handleDeleteClick = useCallback(
    ({ row }: GridRowParams<EndUser>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setEndUserToRemove(row);
      },
    [],
  );

  const defaultColumns = useEndUserListGridColumns();
  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(() => {
    const columns = [...defaultColumns];
    if (isAdmin || !endUserSection.role || hasRole(endUserSection.role)) {
      columns.push({
        field: 'actions',
        type: 'actions',
        headerName: 'Azioni',
        renderHeader: () => <EndUserAddButton />,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem icon={<EditIcon />} key="edit" label="Modifica" onClick={handleEditClick(params)} />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key="delete"
            label="Elimina"
            onClick={handleDeleteClick(params)}
          />,
        ],
      });
    }
    return columns;
  }, [defaultColumns, handleDeleteClick, handleEditClick, hasRole, isAdmin]);

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ p: 1, gap: 1 }}>
        <DataGridWrapper>
          <EndUserListGrid
            columns={columns}
            sessionStorageId="EndUserListPageDataGrid"
            pinnedColumns={pinnedColumns}
            pmFilter={searchParams.get('pm') ?? undefined}
          />
        </DataGridWrapper>
        {endUserToRemove && (
          <EndUserDeleteConfirm
            open={Boolean(endUserToRemove)}
            data={endUserToRemove}
            onClose={() => setEndUserToRemove(null)}
          />
        )}
        {endUserToUpdate && (
          <EndUserEditDialog
            open={Boolean(endUserToUpdate)}
            data={endUserToUpdate}
            onClose={() => setEndEndUserToUpdate(null)}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
